import React, {useState} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import MecenateAppBar from './components/MecenateAppBar';
import Home from './components/Home';
import User from './components/User';
import SettingsPersonal from './components/SettingsPersonal';
import SettingsPortfolio from './components/SettingsPortfolio';
import SettingsCommission from './components/SettingsCommission';
import SettingsProfile from './components/SettingsProfile';
import SettingsAccount from './components/SettingsAccount';
import LoginHome from './components/LoginHome'
import Register from'./components/Register'
import Chat from'./components/Chat'
import ChatWhiteboard from'./components/ChatWhiteboard'
import ChatEngine from'./components/ChatEngine'
import AlertComponent from './components/AlertComponent';
import About from './components/About';
import AboutPhilosophy from './components/AboutPhilosophy';
import AboutHowItWorks from './components/AboutHowItWorks';
import AboutRegistrationForm from './components/AboutRegistrationForm'
import AboutContact from './components/AboutContact'
import AboutPayments from './components/AboutPayments'
import PaymentPage from './components/PaymentPage'

import Authentication from './components/Authentication'

import PrivateRoute from './utils/PrivateRoute';

import RefreshToken from './utils/refreshToken.js'

import Password from'./components/Password.js'

import ErrorPage from './components/ErrorPage';



const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgba(0,0,0,0.7)',
    },
    secondary: {
      main: 'rgba(174,26,22,1)',
    },
    error: {
      main: 'rgba(174,26,22,1)',
    },
    
  },
  typography: {
      allVariants: {
        fontFamily: 'Prompt',
        fontWeight: '300',
        letterSpacing: 1,
    },
  },



});

function App() {


  const [errorMessage, updateErrorMessage] = useState(null);
  const {inbox, conversations, sendMessage, closeChat, unread } = ChatEngine();

  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      
      <div>

      <RefreshToken/>

        <BrowserRouter>
        
        <div>

          

          <Switch>

              <Route path="/enter" >
                <Password/>
              </Route>  

              <Route path="/" exact={true}>
                <div style ={{ backgroundColor: 'rgba(242,242,242,0.24)', height: '100vh'}}>
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <Home/>
                </div>
              </Route>  

              <PrivateRoute path="/SettingsPersonal" >
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <SettingsPersonal/>
              </PrivateRoute>

              <PrivateRoute path="/SettingsPortfolio" >
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <SettingsPortfolio/>
              </PrivateRoute>

              <PrivateRoute path="/SettingsProfile" >
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <SettingsProfile/>
              </PrivateRoute>

              <PrivateRoute path="/SettingsCommission" >
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <SettingsCommission/>
              </PrivateRoute>

              <PrivateRoute path="/SettingsAccount" >
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <SettingsAccount/>
              </PrivateRoute>

              <PrivateRoute path="/WhiteBoard">
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <ChatWhiteboard in_box = {inbox} send_Message = {sendMessage}/>
              </PrivateRoute>

              <Route path="/LoginHome">
                <LoginHome showError={updateErrorMessage} />
              </Route>

              
              <Route path="/Register">
                <Register showError={updateErrorMessage} />
              </Route>

              <Route path="/about">
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <About/>
              </Route>

              <Route path="/philosophy">
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <AboutPhilosophy/>
              </Route>

              <Route path="/howitworks">
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <AboutHowItWorks/>
              </Route>

              <Route path="/registrationform">
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <AboutRegistrationForm/>
              </Route>

              <Route path="/contactus">
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <AboutContact/>
              </Route>

              <Route path="/aboutpayments">
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <AboutPayments/>
              </Route>

              {/* 
              <Route path= "/PaymentPage">
               <PaymentPage/>
              </Route>
              */}
              


              <Route path="/authentication/:authToken">    
                <Authentication/>
              </Route>
            

               <PrivateRoute path="/chat/:conversationID">
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <Chat in_box = {inbox} active_conversations = {conversations} send_Message = {sendMessage}/>
              </PrivateRoute>

              <Route path="/error">
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <ErrorPage/>
              </Route> 

              <Route path="/:user" >
                <MecenateAppBar close_Chat = {closeChat} active_conversations = {conversations} unread_messages = {unread}/>
                <User/>
              </Route>

             


              
              
          </Switch>



          <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage}/>

          </div>
          
         </BrowserRouter>
    </div>
    </ThemeProvider>
  );
}

export default App;
