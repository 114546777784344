import React, {useState, useEffect} from 'react';
import MecenateStackGrid from "./MecenateStackGrid"
import Autocomplete from '@material-ui/lab/Autocomplete';
import Search from "./Search"
import SearchName from "./SearchName"
import HomeCards from './HomeCards'
import LogoAdam from './Logo_adam.js'
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID, PSWD_FLAG} from '../constants/apiConstants'
import { Button } from '@material-ui/core';
import axios from 'axios';
import { Link } from "react-router-dom";
import Switch from '@material-ui/core/Switch';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slider from '@material-ui//core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Pagination from '@material-ui/lab/Pagination';
import PasswordPage from './PasswordPage'
import BottomBar from './BottomBar';
import HomeShortcutsCards from './HomeShortcutsCards'

import PictureGrid from './PictureGrid'
import PictureGridCards from './PictureGridCards';

/*import logo from './logoNew2.png';*/
//import logo from './BannerFLogo.png'
import logo from './artecenatePlusLogo.png'
import magLogo from './magLogo.png'
import klevraFigure from './Klevra_figure.png';
import klevraFigure2 from './Klevra_figure_old.jpeg';
import elviraFigure from './Elvira_figure.jpeg';
import maupalFigure from './super_pope.jpeg';
//import maupalFigure2 from './Maupal_figure2.png';
import umanFigure from './UMAN_figure.jpeg';
import umanFigure2 from './UMAN_figure_old.jpeg';
import stillionFigure from './Stillion_figure.jpg'

import './Home.css'



export const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

function Home(props) {

    const [search, setSearch] = useState('')
    const [results, setResults] = useState([])
    const [firstSearch, firstSearchSet] = useState(0)

    const [autocomplete, autocompleteSet] = useState({})

    const [advancedShow, advancedShowSet] = useState(false)
    const [searchType, searchTypeSet] = useState('image')
    const [paginationCounts, paginationCountsSet] = useState(1)
    const [page, pageSet] = useState(1)
    const [pageCount, pageCountSet] = useState(2)
    
    const [user_type, user_typeSet] = useState('')
    const [city, citySet] = useState('')
    const [state, stateSet] = useState('')
    const [country, countrySet] = useState('')
    const [style, styleSet] = useState('')
    const [education, educationSet] = useState('')
    const [medium, mediumSet] = useState('')
    const [matter, matterSet] = useState('')
    const [price, priceSet] = useState([1, 6])
    const [size, sizeSet] = useState('')


    const [pswdSelector, pswdSelectorSet] = useState(0)

    const pswdFlag = localStorage.getItem(PSWD_FLAG);

    const mobileScreen = useMediaQuery('(max-width: 768px)');


     React.useEffect(() => {


      const token = localStorage.getItem(ACCESS_TOKEN_NAME);
          
          

          axios.get(BASE_URL + 'search/distinct', {
            headers: {
              'Authorization': 'Bearer '+ token,
            }
          })

          .then((res) => {

              
              
              autocompleteSet(res.data)
                
          })

          .catch((error) => {
            console.error(error)
          })   


          pageSet(1)
          pageCountSet(2)
           
          

          const userFilter = '&user_type=' + user_type

          const price_filter = "&price_range="+Math.round(10**price[0])+'-'+Math.round(10**price[1])
          
          const filters = country+state+city+style+education+medium+matter+size+price_filter+userFilter

          

         const requestOne = axios.get(BASE_URL + 'search/'+searchType+'?text='+'green'+ '&limit=10&offset=0'+filters, {
            headers: {'Authorization': 'Bearer '+ token,}
          })

         
          {/* 

          const requestTwo = axios.get(BASE_URL + 'search/image/count?text='+search, {
              headers: {'Authorization': 'Bearer '+ token,}
            })

         
           const requestThree = axios.get(BASE_URL + 'search/users/count?text='+search, {
              headers: {'Authorization': 'Bearer '+ token,}
            })

          */}

         axios.all([requestOne])


          .then(
              axios.spread((...responses) => {

                const responseOne = responses[0];
                setResults(responseOne.data)
                firstSearchSet(1)

                
              {/*     
                const responseTwo = responses[1];
                const responseThree =  responses[2];               

                if (searchType == 'image'){
                paginationCountsSet(responseTwo.data.count)}

                if (searchType == 'user'){
                paginationCountsSet(responseThree.data.count)}

              */} 


              })
            )

          .catch((error) => {
            console.error(error)
          })     
    
       
           

  }, []);


    const handleSearch = () => {

          pageSet(1)
          pageCountSet(2)
           
          const token = localStorage.getItem(ACCESS_TOKEN_NAME);

          const userFilter = '&user_type=' + user_type

          const price_filter = "&price_range="+Math.round(10**price[0])+'-'+Math.round(10**price[1])
          
          const filters = country+state+city+style+education+medium+matter+size+price_filter+userFilter

          

         const requestOne = axios.get(BASE_URL + 'search/'+searchType+'?text='+search+ '&limit=10&offset=0'+filters, {
            headers: {'Authorization': 'Bearer '+ token,}
          })

         
          {/* 

          const requestTwo = axios.get(BASE_URL + 'search/image/count?text='+search, {
              headers: {'Authorization': 'Bearer '+ token,}
            })

         
           const requestThree = axios.get(BASE_URL + 'search/users/count?text='+search, {
              headers: {'Authorization': 'Bearer '+ token,}
            })

          */}

         axios.all([requestOne])


          .then(
              axios.spread((...responses) => {

                const responseOne = responses[0];
                setResults(responseOne.data)
                firstSearchSet(1)

                
              {/*     
                const responseTwo = responses[1];
                const responseThree =  responses[2];               

                if (searchType == 'image'){
                paginationCountsSet(responseTwo.data.count)}

                if (searchType == 'user'){
                paginationCountsSet(responseThree.data.count)}

              */} 


              })
            )

          .catch((error) => {
            console.error(error)
          })    


  };

    const selectSearch = (e) => {

     if(e.target.checked){

      searchTypeSet('user')
     }
     
     if(!e.target.checked){

      searchTypeSet('image')
     }



  }

  const handleSearchChange = (e) => {

     const {id , value} = e.target 
     setSearch(value)


  }

  const handleAdvancedShow = () => {

   advancedShowSet(!advancedShow)

  }

  const handleUser_type = (e) =>{

    user_typeSet(e.target.value)
   
  }
  
  const handleCountry = (e, value) => {
    
    if(value){
    countrySet('&country=' + value)}
    else{
    countrySet('')
    }

  }

  const handleState = (e, value) => {
    
    if(value){
    stateSet('&state=' + value)}
    else{
    stateSet('')
    }

  }

   const handleCity = (e, value) => {
    
    if(value){
    citySet('&city=' + value)}
    else{
    citySet('')
    }

  }

     const handlePrice = (e, value) => {
    
    if(value){
    priceSet(value)}

  }

  const handleMinPrice = (e, value) => {
    
    priceSet([Math.log10(e.target.value),price[1]])

  }

  const handleMaxPrice = (e, value) => {
    
    priceSet([price[0],Math.log10(e.target.value)])

  }

     const handleSize = (e, value) => {
    
    if(value){
    sizeSet('&size=' + value)}
    else{
    sizeSet('')
    }

  }

  const handleStyle = (e, value) => {

    if(value){
    styleSet('&style=' + value)}
    else{
    styleSet('')
    }

  }

   const handleEducation = (e, value) => {

    if(value){
    educationSet('&education=' + value)}
    else{
    educationSet('')
    }

  }

   const handleMedium = (e, value) => {

    if(value){
    mediumSet('&medium=' + value)}
    else{
    mediumSet('')
    }

  }

   const handleMatter = (e, value) => {

    if(value){
    matterSet('&matter=' + value)}
    else{
    matterSet('')
    }

  }

  const handlePagination = (e, value) => {

    //console.log(value)

    //pageSet(value)
    pageCountSet( pageCount + 1)
    //const offset = (value * 10)-10

    const offset = (pageCount * 10)-10

    const token = localStorage.getItem(ACCESS_TOKEN_NAME);

          const userFilter = '&user_type=' + user_type
          const price_filter = "&price_range="+Math.round(10**price[0])+'-'+Math.round(10**price[1])
          
          const filters = country+state+city+style+education+medium+matter+price_filter+size+userFilter



          axios.get(BASE_URL + 'search/'+searchType+'?text='+search+ '&limit=10&offset='+offset+filters, {
            headers: {
              'Authorization': 'Bearer '+ token,
            }
          })

          .then((res) => {

              var newResults = results.concat(res.data)

              //setResults(res.data)
              setResults(newResults)

              
             
                
          })

          .catch((error) => {
            console.error(error)
          })  

  }



 const options = ['San Francisco', 'Toronto'];
 const prices = ['100$ - 500$', '500$ - 2000$'];
 const sizes = ['1sqft', '10sqft'];

 if(pswdSelector == 0 | pswdFlag=='Malevich'){
  
  return (
    <div>
    

      {/* <div className = 'logo'> <LogoAdam /> </div> */}
     
     <div className = 'logoHomeContainer'>
      <img className = 'logoHome' src={logo}  alt="Logo_adam" />
     </div> 
      
      
     <div className='searchBarContainer'>
     <div className='searchBar'>
        <InputBase
          
          style={{ marginLeft:'20px', width:'100%' }}
          value = {search}
          onChange = {handleSearchChange}
          placeholder="Search... (example: portrait, or red abstract)"
          inputProps={{ 'aria-label': 'Search...' }}
          onKeyDown = {(e) => {
                                  if (e.key === 'Enter') {
                                      handleSearch()    
                                            }
                                        }}
        />
        <IconButton         type="submit"  
                            aria-label="search" 
                            onClick={handleSearch} 
                            style={{ marginLeft:'auto' }}>
          <SearchIcon />
        </IconButton>


      </div>


      <Button style={{ marginLeft: 'calc(36vw  )', borderRadius:'5px', fontSize:'10px', maxHeight:'20px',marginTop:'5px' }} variant= 'outlined' onClick = {handleAdvancedShow}> Advanced Search </Button>
      </div>
      <div className = {advancedShow ? "advancedSearch" : "advancedSearchoff"}
                  >


          <div className = 'radioGroup'> 

          <RadioGroup aria-label="user_type" value={user_type} onChange = {handleUser_type} >

            <div >
              <FormControlLabel value="artist" control={<Radio color = 'primary' />} label="Artists" />
              <FormControlLabel value="commissioner" control={<Radio color = 'primary' />} label="Collectors" />
              <FormControlLabel value="" control={<Radio color = 'primary'/>} label="All" />
              </div>
            
         </RadioGroup>

          
          </div>


          <div className = 'advancedSearchBlock' >       
      
                  <Autocomplete

                  onChange={handleCountry}
                  className = 'advancedSearchItem'
                  
                  options={autocomplete.country}
                  renderInput={(params) => <TextField {...params} label="COUNTRY" />}
                />

                 <Autocomplete

                  onChange={handleState}
                  className = 'advancedSearchItem'
                  
                  options={autocomplete.state}
                  renderInput={(params) => <TextField {...params} label="STATE" />}
                />

                 <Autocomplete

                  onChange={handleCity}
                  className = 'advancedSearchItem'
                  
                  options={autocomplete.city}
                  renderInput={(params) => <TextField {...params} label="CITY" />}
                />

                <Autocomplete

                  onChange={handleStyle}
                  className = 'advancedSearchItem'
                  id="style"
                  options={autocomplete.style}
                  renderInput={(params) => <TextField {...params} label="STYLE" />}
                />

                <Autocomplete

                  onChange={handleEducation}
                  className = 'advancedSearchItem'
                  id="style"
                  options={autocomplete.education}
                  renderInput={(params) => <TextField {...params} label="EDUCATION" />}
                />

          </div>

          <div className = 'advancedSearchBlock'>

              <Autocomplete

                onChange={handleMedium}
                className = 'advancedSearchItem'
                id="style"
                options={autocomplete.medium}
                renderInput={(params) => <TextField {...params} label="MEDIUM" />}
              />

              <Autocomplete

                onChange={handleMatter}
                className = 'advancedSearchItem'
                id="style"
                options={autocomplete.matter}
                renderInput={(params) => <TextField {...params} label="MATTER" />}
              />

              <div className = 'advancedSearchPriceRange'>

                <div className = 'advancedSearchPriceRangeTitle'>
                  PRICE RANGE
                </div>
                <Slider
                    
                    value={price}
                    onChange={handlePrice}
                    min={1}
                    max={6}
                    step={0.05}
                    
                   
   
                  />
                <div className = 'advancedSearchPriceRangeLabelsContainer'>
                    <div className = 'advancedSearchPriceRangeLabels'>
                      <InputBase
                        value={Math.round(10**price[0])}
                        onChange={handleMinPrice}
                        type="number"
                      />
                       $ 
                    </div>
                    <div className = 'advancedSearchPriceRangeLabels'>
                      <InputBase
                        value={Math.round(10**price[1])}
                        onChange={handleMaxPrice}
                        type="number"
                      />
                       $ 
                    </div>
                </div>
              </div>

              

              <div className ='searchByName'>  
                
                <p> search by name</p>
                <div className ='searchByNameSwitch'>
                  <Switch color="primary" onChange = {selectSearch} />
                </div>
              </div>
       </div>

      </div>

      {/*
      <div className = {!results[0] ? 'newOnMecenate':'newOnMecenateNone'}>

          <div className = 'newOnMecenateTitle'>
            ALL STREETS LEAD TO ROME: ARTISTS
          </div>

          <PictureGrid unitRow = {mobileScreen ? '4vh' : '12vh'} unitColumn = '10vw'>

            <PictureGridCards pic = {umanFigure} text = {"uman"} link = {'cb8b2670-489d-45ba-a12d-6e4ad2c9a196'} columns = {'1 / 4'} rows ={'1 / 7'}/>
            <PictureGridCards pic = {maupalFigure} text = {"maupal"} link = {'829af1e4-1027-4cea-a4b2-da05a52b79d4'} columns = {'4 / 8'} rows ={'1 / 7'}/>
            <PictureGridCards pic = {klevraFigure2} text = {"Mr. Klevra"} link = {'4bcfbaa7-201c-4927-9b14-425a8c558b82'} columns = {'8 / 11'} rows ={'1 / 7'}/>

          </PictureGrid>


          <div className = 'newOnMecenateTitle'>
            THE HUMAN SHAPE SERIES
          </div>

          <PictureGrid unitRow = {mobileScreen ? '4vh' : '12vh'} unitColumn = '10vw'>
            
            <PictureGridCards pic = {stillionFigure} text = {"Ann Marie Stillion"} link = {'22db5cbc-bc2f-48ee-a4c1-b426c24bee76'} columns = {'4 / 8'} rows ={'1/ 7'}/>
            <PictureGridCards pic = {elviraFigure} text = {"Elvira Dayel"} link = {'096440d3-5d09-4d1a-8268-c0330afa065f'} columns = {'1 / 4'} rows ={'1 / 7'}/>
            <PictureGridCards pic = {umanFigure2} text = {"uman"} link = {'cb8b2670-489d-45ba-a12d-6e4ad2c9a196'} columns = {'8 / 11'} rows ={'1 / 7'}/>
          </PictureGrid>

           
          <div className = 'newOnMecenateTitle'>
            VISIT OUR MAGAZINE
          </div>

          <Link className = 'magMecenateBannerGrid' to= {'//mag.artmecenate.com'} target="_blank">
            
            <div className = 'magMecenateBannerContainer'> <img src = {magLogo} className = 'magMecenateBannerImage'/> </div>
            <div className = 'magMecenateBannerText'> visit our magazine to find the latest news on our artists, commissions, collectors, interviews, critical essays, commissions, and much more from the art world  </div>
           
          </Link>
                                      

          <div className = 'newOnMecenateGrid'>

            <HomeShortcutsCards pic = {klevraFigure} text = {"Mr. Klevra"} link = {'4bcfbaa7-201c-4927-9b14-425a8c558b82'} />
            <HomeShortcutsCards pic = {umanFigure} text = {"UMAN"} link = {'cb8b2670-489d-45ba-a12d-6e4ad2c9a196'} />
            <HomeShortcutsCards pic = {maupalFigure} text = {"Maupal"} link = {'829af1e4-1027-4cea-a4b2-da05a52b79d4'}/>
            <HomeShortcutsCards pic = {elviraFigure} text = {"Elvira Dayel"} link = {'096440d3-5d09-4d1a-8268-c0330afa065f'}/>
          </div>
      </div> 

        */}

      
      
      
       
       
{/*
      <div>
      { [...Array(pageCount-1)].map((x, k) => (

       
          <div className = 'grid' key = {k}>

                { results[0] || !firstSearch ? (
                  results.slice(k*10,10+k*10).map((imageCard, i) => (  
                      <HomeCards image = {imageCard} key = {i} index = {i}/>

                  ))     
                  ): 
                (<div style ={{ fontWeight: 'bold', fontVariant: 'small-caps' }}> No results for this search, sorry! </div>)     
                }

          </div>
         

        ))}
      </div>

    */}

    <div className = 'grid' >

         <div className = 'gridColumn'>
            { results.map((imageCard, i) => (  i%3==0 ? (
                 <HomeCards image = {imageCard} key = {i} index = {'a'+i}/> ) : ''
                  ))              
            }
         </div>

         <div className = 'gridColumn'>
            { results.map((imageCard, i) => (  i%3==1 ? (
                 <HomeCards image = {imageCard} key = {i} index = {'b'+i}/> ) : ''
                  ))              
            }
         </div>

         <div className = 'gridColumn'>
            { results.map((imageCard, i) => (  i%3==2 ? (
                 <HomeCards image = {imageCard} key = {i} index = {'c'+i}/> ) : ''
                  ))              
            }
         </div>

          <div className = 'gridColumnMobile'>
            { results.map((imageCard, i) => (  
                 <HomeCards image = {imageCard} key = {i} index = {'d'+i}/>  
                  ))              
            }
         </div>

          </div>



      <div style = {{display: 'flex', marginBottom: '50px', marginTop:'50px'}}>

        {/*<Pagination 
         onChange = {handlePagination}
         count={paginationCounts} 
         page = {page}
         variant="outlined" 
         color="primary" 
         size = 'large'
         style = {{display: results[0]  ? "" : "none", marginLeft: 'auto', marginRight: 'auto' }}
         /> */}   

         <Button 
           variant="outlined"
           style = {{display: results[0] && results[10*(pageCount-1)-1] ? "" : "none", marginLeft: 'auto', marginRight: 'auto' }}
           onClick = {handlePagination}>
          More results

         </Button>

      </div>

      <div className = {results[0] ? 'bottomBarRelative' : 'bottomBarFixed'}>
      <BottomBar />
      </div>

      

    </div>
  )}

  if(pswdSelector==1){

    return(<PasswordPage locker = {pswdSelectorSet}/>)
  }

}

export default Home;
