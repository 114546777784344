import React from 'react';
import {Button } from '@material-ui/core';
import {BASE_URL, HOME} from '../constants/apiConstants'
import './HamburgerMenu.css'  


export default function HamburgerMenu(props) {


  const HamburgerButton = {

                              fontSize: '30px',
                              fontWeight: '200',
                              marginTop: '2px',
                              
  }


  const artLink = () => {
    window.location = HOME + 'ART/artIndex'
  }
  
  const artistsLink = () => {
    window.location = HOME + 'ARTISTS/artistsIndex'
  }
  
  const collectorsLink = () => {
    window.location = HOME + 'COLLECTORS/collectorsIndex'
  }
  
  const commissionersLink = () => {
    window.location = HOME + 'COMMISSIONERS/commissionersIndex'
  }
  
  const focusLink = () => {
    window.location = HOME + 'focus'
  }
  
  const homeLink = () => {
    window.location = HOME 
  }
  
  const artMecenateLink = () => {
    window.location = 'https://artmecenate.com'
  }
  

  if(props.flag){  
    return (
        <div> 
        <div className='HamburgerEntriesContainer'>
              
              <Button  style= {HamburgerButton} onClick ={homeLink} > HOME </Button>
              <Button  style= {HamburgerButton} onClick ={artLink} > art </Button>
              <Button  style= {HamburgerButton} onClick ={artistsLink} > artists </Button>
              <Button  style= {HamburgerButton} onClick ={collectorsLink} > collectors </Button>
              <Button  style= {HamburgerButton} onClick ={focusLink}> focus </Button>
              <Button  style= {HamburgerButton} onClick ={artMecenateLink}> artmecenate </Button>
        </div>
        

        </div>
    )
  }
  if(!props.flag){

    return(<div style={{display: 'none'}}></div>)
  } 
}